import { supabase } from "@/app/config/supabase";

export async function getAllPlayers(): Promise<Player[]> {
  try {
    const request = await supabase.from("player").select("*");

    if (request.error) throw request.error;

    return request.data as Player[];
  } catch (e) {
    throw e;
  }
}

export async function getPlayerBySessionId(
  session_id: string
): Promise<Player[]> {
  try {
    const request = await supabase
      .from("player")
      .select("*")
      .eq("session_id", session_id);

    if (request.error) throw request.error;

    return request.data as Player[];
  } catch (e) {
    throw e;
  }
}

export async function getPlayerByName(name: string): Promise<Player> {
  try {
    const request = await supabase.from("player").select("*").eq("name", name);

    if (request.error) throw request.error;

    return request.data[0] as Player;
  } catch (e) {
    throw e;
  }
}

export async function createPlayer(player: Player): Promise<Player> {
  try {
    const request = await supabase.from("player").insert(player).select();

    if (request.error) throw request.error;

    return request.data[0] as Player;
  } catch (e) {
    throw e;
  }
}
