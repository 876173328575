import { Heading1, Paragraph } from "@/components/atoms";
import { DefaultLayout } from "@/components/templates";
import { getAllInvitationsBySession } from "@/services/invitation.service";
import { useQuery } from "react-query";
import RoomPlayer from "./_components/RoomPlayer";
import RoomEmptyPlayer from "./_components/RoomEmptyPlayer";
import { notification } from "antd";
import { useRealtimeInvitations } from "@/hooks/useRealtimeInvitations";
import { useSessionId } from "./hooks/useSessionId";
import { getRoomById } from "@/services/room.service";
import RoomStartParty from "./_components/RoomStartParty";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRealtimeSession } from "@/hooks/useRealtimeSession";

export default function RoomPage() {
  let navigate = useNavigate();
  const sessionId = useSessionId();
  const LIMIT_PLAYERS = 4;

  useRealtimeInvitations("session_id", sessionId);
  useRealtimeSession(sessionId);

  const { data: invitations, isLoading: invitationsLoading } = useQuery(
    ["invitations", sessionId],
    () => getAllInvitationsBySession(sessionId),
    {
      enabled: !!sessionId,
      onError: (error) => {
        console.error(error);
        notification.error({ message: (error as Error).message });
      },
    }
  );

  const { data: session } = useQuery(
    ["session", sessionId],
    () => getRoomById(sessionId),
    {
      enabled: !!sessionId,
    }
  );

  useEffect(() => {
    if (session?.started) navigate("/game");
  }, [navigate, session]);

  return (
    <DefaultLayout>
      <div className="px-10 py-14 rounded-3xl bg-primary-800 flex flex-col gap-10 max-w-[500px] w-full mx-auto">
        <div className="flex justify-between items-end">
          <Heading1>{session?.name}</Heading1>
          <Paragraph>
            {invitations?.length}/{LIMIT_PLAYERS}
          </Paragraph>
        </div>
        {invitationsLoading && <Paragraph>Chargement...</Paragraph>}

        {!invitationsLoading && (
          <ul className="divide-y divide-primary-700">
            {invitations &&
              invitations.map((invitation) => (
                <RoomPlayer key={invitation.id} invitation={invitation} />
              ))}

            {(invitations?.length ?? 0) < LIMIT_PLAYERS &&
              Array.from({
                length: LIMIT_PLAYERS - (invitations?.length ?? 0),
              }).map((_, i) => (
                <RoomEmptyPlayer
                  key={Date.now() + i}
                  sessionId={sessionId}
                  count={i + (invitations?.length ?? 0) + 1}
                />
              ))}
          </ul>
        )}

        <div>
          <Paragraph>Code: {session?.code}</Paragraph>
          {session ? <RoomStartParty room={session} /> : null}
        </div>
      </div>
    </DefaultLayout>
  );
}
