import { CloseIcon, Paragraph } from "@/components/atoms";
import joinClasses from "@/helpers/joinClasses";
import { deleteInvitation } from "@/services/invitation.service";
import { notification } from "antd";
import { useMutation } from "react-query";

type RoomPlayerProps = {
  invitation: InvitationWithPlayer;
};

export default function RoomPlayer({ invitation }: RoomPlayerProps) {
  const getCurrentUser = () => {
    const user = JSON.parse(localStorage.getItem("player") as string) as Player;
    return user;
  };

  const mutation = useMutation(deleteInvitation, {
    onError: (error) => {
      notification.error({ message: (error as Error).message });
    },
  });

  const handleDelete = () => {
    if (mutation.isLoading) return;
    mutation.mutate(invitation.id || "");
  };

  return (
    <div>
      <li
        key={invitation.id}
        className="flex gap-5 items-center justify-between py-5 relative group"
      >
        <div
          className={joinClasses(
            "flex gap-5 items-center",
            invitation.status === "pending" ? "opacity-50" : "",
            invitation.status === "rejected" ? "opacity-50" : ""
          )}
        >
          <div className="w-14 h-14 relative rounded-full overflow-hidden">
            <img
              src={invitation.player.avatar}
              alt="avatar"
              className={joinClasses(
                "w-14 h-14 rounded-full",
                invitation.player.id === getCurrentUser()?.id
                  ? "border-4 border-secondary"
                  : ""
              )}
            />
          </div>
          <Paragraph>
            {invitation.player.name}
            {invitation.status === "rejected" && " - refusé"}
          </Paragraph>
        </div>
        {invitation.status !== "accepted" && (
          <span
            className="text-4xl text-red-400 hidden group-hover:block cursor-pointer"
            onClick={handleDelete}
          >
            <CloseIcon />
          </span>
        )}
      </li>
    </div>
  );
}
