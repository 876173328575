import { useEffect } from "react";
import { supabase } from "@/app/config/supabase";
import { useQueryClient } from "react-query";

export function useRealtimeSession(sessionId: string) {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (sessionId) {
      const subscription = supabase
        .channel("realtime:session")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "session",
            filter: `id=eq.${sessionId}`,
          },
          () => {
            queryClient.invalidateQueries(["session", sessionId]);
          }
        )
        .subscribe();

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [sessionId, queryClient]);
}
