import { DefaultLayout } from "@/components/templates";
import HomeRegister from "./_components/HomeRegister";
import { useState } from "react";
import HomeAction from "./_components/HomeAction";

export default function HomePage() {
  const [state, setState] = useState(1);
  return (
    <DefaultLayout>
      {state === 1 && <HomeRegister handleSuccess={() => setState(2)} />}
      {state === 2 && <HomeAction />}
    </DefaultLayout>
  );
}
