import { PrimaryButton } from "@/components/atoms";
import { updateRoom } from "@/services/room.service";
import { useMutation } from "react-query";

export default function RoomStartParty({ room }: { room: Room }) {
  const mutation = useMutation(updateRoom, {
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const handleStartParty = () => {
    mutation.mutate({
      ...room,
      started: true,
    });
  };

  return (
    <div>
      <PrimaryButton onClick={handleStartParty}>
        {mutation.isLoading ? "Chargement..." : "Commencer la partie"}
      </PrimaryButton>
    </div>
  );
}
