import { useEffect } from "react";
import { supabase } from "@/app/config/supabase";
import { useQueryClient } from "react-query";

export function useRealtimeInvitations(key: string, value: string) {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (value) {
      const subscription = supabase
        .channel("realtime:invitation")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "invitation",
            filter: `${key}=eq.${value}`,
          },
          () => {
            queryClient.invalidateQueries(["invitations", value]);
          }
        )
        .subscribe();

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [value, queryClient, key]);
}
