import { RefreshIcon } from "@/components/atoms";

type HomeAvatarProps = {
  handleChange: () => void;
  avatarUrl: string;
};

export default function HomeAvatar({
  handleChange,
  avatarUrl,
}: HomeAvatarProps) {
  return (
    <div className="relative">
      <img
        src={avatarUrl}
        alt="avatar"
        className="w-[200px] bg-primary rounded-full"
      />
      <div
        className="absolute right-0 bottom-0 rounded-full bg-primary text-4xl p-3 cursor-pointer text-white hover:bg-secondary transition-all hover:rotate-180 active:scale-90"
        onClick={handleChange}
      >
        <RefreshIcon />
      </div>
    </div>
  );
}
