import { LetterIcon, OwnerIcon, Paragraph } from "@/components/atoms";
import { createRoom } from "@/services/room.service";
import { LoadingOutlined } from "@ant-design/icons";
import { notification, Spin } from "antd";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";

export default function HomeAction() {
  let navigate = useNavigate();

  const mutation = useMutation(createRoom, {
    onSuccess: (data: Room) => {
      navigate(`/room?id=${data.id}`);
    },
    onError: (error) => {
      notification.error({ message: (error as Error).message });
    },
  });

  const handleCreateRoom = () => {
    const player = JSON.parse(
      localStorage.getItem("player") as string
    ) as Player;
    mutation.mutate(player.id as string);
  };

  if (mutation.isLoading) {
    return (
      <div className="h-screen w-screen flex justify-center items-center bg-primary">
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: 48 }}
              className="text-white"
              spin
            />
          }
        />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-10">
      <div
        className="px-10 py-14 rounded-3xl bg-primary-900 flex flex-col gap-10 items-center hover:scale-105 transition-transform duration-300 group cursor-pointer active:scale-100"
        onClick={handleCreateRoom}
      >
        <div className="text-[80px] text-white group-hover:rotate-12 group-hover:scale-110 transition-transform duration-300">
          <OwnerIcon />
        </div>

        <Paragraph>Créer un salon</Paragraph>
      </div>
      <Link
        className="px-10 py-14 rounded-3xl bg-primary-900 flex flex-col gap-10 items-center hover:scale-105 transition-transform duration-300 group cursor-pointer active:scale-100"
        to="/join"
      >
        <div className="text-[80px] text-white group-hover:rotate-12 group-hover:scale-110 transition-transform duration-300">
          <LetterIcon />
        </div>

        <Paragraph>Rejoindre un salon</Paragraph>
      </Link>
    </div>
  );
}
