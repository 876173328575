import { Heading1, Paragraph } from "@/components/atoms";
import { DefaultLayout } from "@/components/templates";
import { getInvitationByPlayerId } from "@/services/invitation.service";
import { usePlayer } from "./hooks/usePlayer";
import { notification } from "antd";
import { useRealtimeInvitations } from "@/hooks/useRealtimeInvitations";
import InvitationItem from "./_components/InvitationItem";
import InvitationCode from "./_components/InvitationCode";
import { useQuery } from "react-query";

export default function InvitePage() {
  const player = usePlayer();
  useRealtimeInvitations("player_id", player?.id ?? "");

  const { data: invitations, isLoading: invitationsLoading } = useQuery(
    ["invitations", player?.id ?? ""],
    () => getInvitationByPlayerId(player?.id ?? ""),
    {
      enabled: !!player,
      onError: (error: Error) => {
        console.error(error);
        notification.error({ message: (error as Error).message });
      },
    }
  );

  return (
    <DefaultLayout>
      <div className="px-10 py-14 rounded-3xl bg-primary-800 flex flex-col gap-10 max-w-[500px] w-full mx-auto">
        <div className="flex justify-between items-end">
          <Heading1>Invitations</Heading1>
        </div>
        {invitationsLoading ? (
          <Paragraph>Chargement...</Paragraph>
        ) : (
          <ul className="divide-y divide-primary-700">
            {invitations?.length ? (
              invitations
                .filter(
                  (invitation: InvitationWithSession) =>
                    invitation.status === "pending"
                )
                .map((invitation: InvitationWithSession) => (
                  <InvitationItem key={invitation.id} invitation={invitation} />
                ))
            ) : (
              <Paragraph>Vous n'avez pas d'invitation en attente</Paragraph>
            )}
          </ul>
        )}
      </div>
      <InvitationCode player={player} />
    </DefaultLayout>
  );
}
