type DefaultLayoutProps = {
  children: React.ReactNode;
};

export function DefaultLayout({ children }: DefaultLayoutProps) {
  return (
    <div
      className="bg-cover bg-center"
      style={{ backgroundImage: "url('/assets/images/background.jpg')" }}
    >
      <div className="flex flex-col items-center justify-center w-screen h-screen gap-10 bg-gradient-to-b from-transparent to-[#043A56] to-95%">
        {children}
      </div>
    </div>
  );
}
