import { useState, useEffect } from "react";

export function useSessionId() {
  const [sessionId, setSessionId] = useState("");

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const id = search.get("id");
    if (id) setSessionId(id);
  }, []);

  return sessionId;
}
