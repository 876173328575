import { Heading1, Input, Paragraph, PrimaryButton } from "@/components/atoms";
import HomeAvatar from "./HomeAvatar";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { createPlayer } from "@/services/player.service";
import { notification } from "antd";

type HomeRegisterProps = {
  handleSuccess: () => void;
};

export default function HomeRegister({ handleSuccess }: HomeRegisterProps) {
  const randomizeAvatar = () => {
    // return random avatar between 1 and 100
    return `https://avatar.iran.liara.run/public/${
      Math.floor(Math.random() * 100) + 1
    }`;
  };

  const [user, setUser] = useState({
    name: "",
    avatar: randomizeAvatar(),
  });

  const mutation = useMutation(createPlayer, {
    onSuccess: (data: Player) => {
      console.log(data);
      localStorage.setItem("player", JSON.stringify(data));
      handleSuccess();
    },
    onError: (error) => {
      notification.error({ message: (error as Error).message });
    },
  });

  const handleSubmit = () => {
    if (mutation.isLoading) return;
    if (user.name.trim() === "") {
      notification.warning({ message: "Le nom ne peut pas être vide." });
      return;
    }
    mutation.mutate({ name: user.name, avatar: user.avatar });
  };

  useEffect(() => {
    const player = localStorage.getItem("player");
    if (player) handleSuccess();
  }, [handleSuccess]);

  return (
    <div className="px-10 py-14 rounded-3xl bg-primary-800 flex flex-col gap-10">
      <div className="flex gap-10 items-center">
        <HomeAvatar
          handleChange={() => setUser({ ...user, avatar: randomizeAvatar() })}
          avatarUrl={user.avatar}
        />
        <div className="flex flex-col gap-5">
          <div>
            <Heading1>Bienvenue !</Heading1>
            <Paragraph>Entrez votre nom et choisissez un avatar</Paragraph>
          </div>
          <Input
            placeholder="Entrez votre nom"
            name="name"
            type="text"
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            value={user.name}
            autoFocus
          />
        </div>
      </div>
      <PrimaryButton onClick={handleSubmit}>
        {mutation.isLoading ? "Chargement..." : "Démarrer"}
      </PrimaryButton>
    </div>
  );
}
