import { useState, useEffect } from "react";

export function usePlayer() {
  const [player, setPlayer] = useState<Player | null>(null);

  useEffect(() => {
    const player = localStorage.getItem("player");
    if (player) setPlayer(JSON.parse(player));
  }, []);

  return player;
}
