import { Input } from "@/components/atoms";
import { createInvitationWithSessionCode } from "@/services/invitation.service";
import { notification } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

export default function InvitationCode({ player }: { player: Player | null }) {
  let navigate = useNavigate();

  const mutation = useMutation(createInvitationWithSessionCode, {
    onError: (error) => {
      notification.error({ message: (error as Error).message });
    },
    onSuccess: (payload: Invitation) => {
      if (payload.status === "accepted") {
        navigate(`/room?id=${payload.session_id}`);
      }
    },
  });

  const handleSubmitCode = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const sessionCode = formData.get("session") as string;
    mutation.mutate({ sessionCode, player: player?.name ?? "" });
  };
  return (
    <form
      className="px-10 py-14 rounded-3xl bg-primary-800 flex flex-col gap-10 max-w-[500px] w-full mx-auto"
      onSubmit={handleSubmitCode}
    >
      <Input placeholder="Code du salon" name="session" type="number" />
    </form>
  );
}
