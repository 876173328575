import { supabase } from "@/app/config/supabase";
import { getPlayerByName } from "./player.service";

export async function getAllInvitationsBySession(
  session_id: string
): Promise<InvitationWithPlayer[]> {
  try {
    const { data, error } = await supabase
      .from("invitation")
      .select("*, player:player_id (*)")
      .eq("session_id", session_id);

    if (error) throw error;

    return data as InvitationWithPlayer[];
  } catch (e) {
    throw e;
  }
}

interface InvitationParams {
  sessionId: string;
  player: string;
  status?: Invitation["status"];
}

export async function getInvitationByPlayerId(
  playerId: string
): Promise<InvitationWithSession[]> {
  try {
    // only invitation in pending status
    const { data, error } = await supabase
      .from("invitation")
      .select("*, session:session_id (*)")
      .eq("status", "pending")
      .eq("player_id", playerId);

    if (error) throw error;

    return data as InvitationWithSession[];
  } catch (e) {
    throw e;
  }
}

export async function createInvitationWithSessionCode({
  sessionCode,
  player,
}: {
  sessionCode: string;
  player: string;
}) {
  try {
    const session = await supabase
      .from("session")
      .select()
      .eq("code", sessionCode);

    if (session.error) throw session.error;
    if (!session.data.length) throw new Error("Salon introuvable");

    const invitation = await createInvitation({
      sessionId: session.data[0].id,
      player,
      status: "accepted",
    });

    return invitation;
  } catch (e) {
    throw e;
  }
}

export async function createInvitation({
  sessionId,
  player,
  status = "pending",
}: InvitationParams) {
  try {
    const playerData = await getPlayerByName(player);

    if (!playerData) throw new Error("Joueur introuvable");

    const { data, error } = await supabase
      .from("invitation")
      .upsert({
        session_id: sessionId,
        player_id: playerData.id,
        status,
      })
      .select();

    if (error) throw error;
    if (!data) throw new Error("Erreur lors de la création de l'invitation");

    return data[0] as Invitation;
  } catch (e) {
    throw e;
  }
}

export async function answerInvitation({
  invitationId,
  status,
}: {
  invitationId: string;
  status: Invitation["status"];
}) {
  try {
    const { data, error } = await supabase
      .from("invitation")
      .update({ status })
      .eq("id", invitationId)
      .select();

    console.log(data);
    if (error) throw error;
    if (!data) throw new Error("Erreur lors de la mise à jour de l'invitation");

    return data[0] as Invitation;
  } catch (e) {
    throw e;
  }
}

export async function deleteInvitation(id: string) {
  try {
    const { error } = await supabase.from("invitation").delete().eq("id", id);

    if (error) throw error;
  } catch (e) {
    throw e;
  }
}
