import joinClasses from "@/helpers/joinClasses";

type Heading1Props = {
  children: React.ReactNode;
  className?: string;
};

export function Heading1({ children, className }: Heading1Props) {
  return (
    <h1
      className={joinClasses("text-6xl text-white text-secondary", className)}
    >
      {children}
    </h1>
  );
}
