import joinClasses from "@/helpers/joinClasses";

type ParagraphProps = {
  children: React.ReactNode;
  className?: string;
};

export function Paragraph({ children, className }: ParagraphProps) {
  return (
    <p className={joinClasses("text-2xl text-white", className)}>{children}</p>
  );
}
