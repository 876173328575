import { Input, Paragraph } from "@/components/atoms";
import { createInvitation } from "@/services/invitation.service";
import { notification } from "antd";
import { useMutation } from "react-query";
import { FormEvent, useState } from "react";

export default function RoomEmptyPlayer({
  sessionId,
  count,
}: {
  sessionId: string;
  count: number;
}) {
  const [player, setPlayer] = useState("");
  const [loading, setLoading] = useState(false);
  const mutation = useMutation(createInvitation, {
    onError: (error) => {
      notification.error({ message: (error as Error).message });
      setLoading(false);
    },
    onSuccess: () => {
      setPlayer("");
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    mutation.mutate({ sessionId, player });
  };

  return (
    <li className="flex gap-5 items-center border-b border-primary-700 py-5">
      <form onSubmit={handleSubmit}>
        {loading ? (
          <Paragraph>Chargement...</Paragraph>
        ) : (
          <Input
            placeholder={`Joueur #${count}`}
            name="player"
            type="text"
            value={player}
            onChange={(e) => setPlayer(e.target.value)}
          />
        )}
      </form>
    </li>
  );
}
