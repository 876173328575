import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

// Pages
import InvitePage from "./pages/invite/invite.page";
import HomePage from "./pages/home/home.page";
import RoomPage from "./pages/room/room.page";
import GamePage from "./pages/game/game.page";

const queryClient = new QueryClient();

const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const player = localStorage.getItem("player");

  if (!player || !JSON.parse(player).id) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default function AppRouter() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route
            path="/join"
            element={
              <RequireAuth>
                <InvitePage />
              </RequireAuth>
            }
          />

          <Route
            path="/room"
            element={
              <RequireAuth>
                <RoomPage />
              </RequireAuth>
            }
          />

          <Route
            path="/game"
            element={
              <RequireAuth>
                <GamePage />
              </RequireAuth>
            }
          />

          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}
