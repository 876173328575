import { supabase } from "@/app/config/supabase";

export async function getRoomById(id: string): Promise<Room> {
  try {
    const request = await supabase.from("session").select().eq("id", id);

    if (request.error) throw request.error;

    return request.data[0] as Room;
  } catch (e) {
    throw e;
  }
}

export async function updateRoom({ id, ...room }: Room): Promise<Room> {
  try {
    const request = await supabase
      .from("session")
      .update(room)
      .eq("id", id)
      .select();

    if (request.error) throw request.error;
    if (!request.data) throw new Error("Une erreur est survenue");

    return request.data[0] as Room;
  } catch (e) {
    throw e;
  }
}

export async function createRoom(player_id: string): Promise<Room> {
  try {
    const request = await supabase
      .from("session")
      .insert({
        name: `Salon #${Math.floor(Math.random() * 1000)}`,
        code: Math.floor(100000 + Math.random() * 900000).toString(),
      })
      .select();

    if (request.error) throw request.error;

    const room = request.data[0] as Room;

    await supabase.from("invitation").insert([
      {
        player_id,
        session_id: room.id,
        status: "accepted",
      },
    ]);

    return room as Room;
  } catch (e) {
    throw e;
  }
}
