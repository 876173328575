import joinClasses from "@/helpers/joinClasses";

type InputProps = {
  type: "text" | "password" | "email" | "number" | "tel" | "url" | "date";
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  className?: string;
  name: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function Input({
  type,
  placeholder,
  required,
  className,
  name,
  onChange,
  autoFocus,
  value,
}: InputProps) {
  return (
    <input
      autoFocus={autoFocus}
      required={required}
      type={type}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      className={joinClasses(
        "text-3xl px-10 py-5 border-0 border-green-100 rounded-xl bg-primary outline-none text-white placeholder-white/70",
        className
      )}
    />
  );
}
