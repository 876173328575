import joinClasses from "@/helpers/joinClasses";
import { useEffect, useState } from "react";

export default function GameCounter() {
  const [count, setCount] = useState(3);

  useEffect(() => {
    if (count === 0) return;

    const interval = setInterval(() => {
      setCount((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [count]);

  return (
    <div
      className={joinClasses(
        "absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-primary",
        count === 0
          ? "transition-all duration-1000 transform translate-y-full"
          : ""
      )}
    >
      <div className="text-9xl text-white">{count === 0 ? "GO !" : count}</div>
    </div>
  );
}
