import joinClasses from "@/helpers/joinClasses";

type PrimaryButtonProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};
export function PrimaryButton({
  children,
  className,
  onClick,
}: PrimaryButtonProps) {
  return (
    <button
      onClick={onClick}
      className={joinClasses(
        "text-3xl px-10 py-5 mt-10 w-full bg-primary-300 rounded-xl text-white active:!shadow-none transition active:translate-y-[5px] active:bg-primary-300 hover:bg-primary-200",
        className
      )}
      style={{ boxShadow: "0px 5px #12A67C" }}
    >
      {children}
    </button>
  );
}
