import Spline from "@splinetool/react-spline";
import GameCounter from "./_components/GameCounter";

export default function GamePage() {
  return (
    <div>
      <GameCounter />
      <Spline
        scene="https://prod.spline.design/IPaGOripP0KFxkBT/scene.splinecode"
        className="!w-screen !h-screen"
      />
    </div>
  );
}
