import { CheckIcon, CloseIcon, Paragraph } from "@/components/atoms";
import { answerInvitation } from "@/services/invitation.service";
import { notification } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

export default function InvitationItem({
  invitation,
}: {
  invitation: InvitationWithSession;
}) {
  let navigate = useNavigate();

  const mutation = useMutation(answerInvitation, {
    onError: (error) => {
      notification.error({ message: (error as Error).message });
    },
    onSuccess: (payload: Invitation) => {
      if (payload.status === "accepted") {
        navigate(`/room?id=${payload.session_id}`);
      }
    },
  });

  const handleAnswer = (status: "accepted" | "rejected", id: string) => {
    mutation.mutate({ status, invitationId: id });
  };

  return (
    <li
      key={invitation.id}
      className="flex gap-5 items-center justify-between py-5"
    >
      <Paragraph>{invitation.session.name}</Paragraph>

      <div className="flex gap-5 items-center text-2xl">
        <div
          className="cursor-pointer w-10 h-10 bg-red-500 text-white flex justify-center items-center rounded-full hover:scale-110 transition-transform duration-300"
          onClick={() => handleAnswer("rejected", invitation?.id ?? "")}
        >
          <CloseIcon />
        </div>
        <div
          className="cursor-pointer w-10 h-10 bg-green-500 text-white flex justify-center items-center rounded-full hover:scale-110 transition-transform duration-300"
          onClick={() => handleAnswer("accepted", invitation?.id ?? "")}
        >
          <CheckIcon />
        </div>
      </div>
    </li>
  );
}
